import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { graphql } from "gatsby"

//images
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//form
// import WebriQForm from "@webriq/gatsby-webriq-form"

//icons
// import VolunteerIcon from "@images/volunteer-icon.svg"

const Volunteer = ({ location, data }) => {
  const heroBgImg = getImage(data.heroBg)
  return (
    <Layout location={location}>
      <Seo title="Volunteer" />
      <BgImage image={heroBgImg} className="w-100 h-100">
        <div id="volunteer" className="text-white">
          <div className="container">
            <span
              className="text-uppercase text-lightblue"
              style={{ fontWeight: 700 }}
            >
              Join Us
            </span>
            <h1 className="display-3 mb-5" style={{ fontWeight: 800 }}>
              Volunteer
            </h1>
            <div className="row justify-content-between">
              <div className="col-md-10 col-lg-6 mb-5 mb-lg-0">
                <div className="d-grid gap-4">
                  <p className="lead" style={{ fontWeight: 400 }}>
                    Thank you for your interest in volunteering.  Our mission is
                    to help the mental health of youth and organizations that
                    work to do the same.  The FLUO Foundation is in constant
                    need of volunteers throughout the year.  If you live in
                    Colorado, are interested in rallying behind our youth, you
                    can sign up by filling out and submitting the short form
                    here and we will get back with you.  Feel free to let us
                    know in the message why this is important for you, too.
                  </p>
                  {/* <p className="lead" style={{ fontWeight: 400 }}>
                    Thank you again for your interest in volunteering for the
                    FLUO Foundation.  Together we can make a difference!  
                  </p> */}
                </div>
              </div>
              <div className="col-md-10 col-lg-6">
                <div className="hero-form-wrapper p-5 rounded-25">
                  <p className="lead" style={{ fontWeight: 600 }}>
                    Thank you for helping us inspire our youth to feel alive.
                    Collectively, we are building a Foundation for their future.
                  </p>
                  <iframe
                    scrolling="no"
                    src="https://www.forms.fluofoundation.org/utvgym"
                    style={{ border: "0px;", width: "100%", height: "300px" }}
                  ></iframe>
                  {/* <WebriQForm
                    name="Volunteer Form"
                    data-form-id="6147c2b2-4646-4fe2-b3b0-6e6a3bcd360d"
                    data-thankyou-url="/thank-you"
                  >
                    <div className="row">
                      <div className="col-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          name="first-name"
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          name="last-name"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          required
                        />
                      </div>

                      <div className="col-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          name="mobile"
                          placeholder="Mobile (SMS)"
                          required
                        />
                      </div>

                      <div className="col-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          name="state"
                          placeholder="State"
                          required
                        />
                      </div>

                      <div className="col-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          name="city"
                          placeholder="City"
                          required
                        />
                      </div>

                      <div className="col-12 mb-3">
                        <textarea
                          className="form-control"
                          rows="5"
                          name="comments"
                          placeholder="Comments"
                        />
                      </div>

                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <div className="webriq-recaptcha" />
                        </div>
                      </div>

                      <Link
                        className="btn btn-primary volunteer-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center"
                        role="button"
                        href="/donate.html"
                        style={{ width: 186, height: 54, fontWeight: 600 }}
                      >
                        Volunteer
                        <img
                          src={VolunteerIcon}
                          alt="Volunteer Icon"
                          className="ms-2"
                        />
                      </Link>

                      <div className="col-md-6 mb-3">
                        <button
                          className="btn btn-primary d-flex align-items-center"
                          type="submit"
                          style={{ fontWeight: 600 }}
                        >
                          Volunteer
                          <img
                            src={VolunteerIcon}
                            alt="Volunteer Icon"
                            className="ms-2"
                            style={{ width: "20%" }}
                          />
                        </button>
                      </div>
                    </div>
                  </WebriQForm> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BgImage>
    </Layout>
  )
}

export default Volunteer

export const volunteerPageQuery = graphql`
  query volunteerPageQuery {
    heroBg: file(relativePath: { eq: "volunteer-bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          webpOptions: { quality: 70 }
          quality: 50
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
